import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@sb/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';

import { SbSlideToggleComponent } from './slide-toggle/sb-slide-toggle.component';

const components = [SbSlideToggleComponent];

@NgModule({
  imports: [ReactiveFormsModule, CommonModule, InputSwitchModule, TranslateModule, TooltipModule],
  declarations: components,
  exports: components,
})
export class SbFormModules {
  public static forRoot(): ModuleWithProviders<SbFormModules> {
    return {
      ngModule: SbFormModules,
    };
  }
}
