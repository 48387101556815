import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface InputSwitchOnChangeEvent {
  originalEvent: Event;
  checked: boolean;
}

@Component({
  selector: 'sb-slide-toggle',
  templateUrl: './sb-slide-toggle.component.html',
  styleUrls: ['sb-slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbSlideToggleComponent {
  @Input()
  public label: string;

  @Input()
  public labelStyleClasses: string;

  @Input()
  public formInputCtrl: UntypedFormControl;

  @Output()
  public toggleChange = new EventEmitter<InputSwitchOnChangeEvent>();
}
